import { render, staticRenderFns } from "./selectTree2.vue?vue&type=template&id=8207cd08&scoped=true"
import script from "./selectTree2.vue?vue&type=script&lang=js"
export * from "./selectTree2.vue?vue&type=script&lang=js"
import style0 from "./selectTree2.vue?vue&type=style&index=0&id=8207cd08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8207cd08",
  null
  
)

export default component.exports